import React from 'react';
import parse from 'html-react-parser';
import {
  transcriptContainer,
  showScriptBtn,
  scriptShowText,
  scriptHideText,
} from './TranscriptButton.module.scss';

const TranscriptButton = ({ transcript, color, isHome }) => {
  return (
    <details className={`${transcriptContainer}`}>
      <summary
        className={`h4 pb24 varColor${color} varLinkColor ${showScriptBtn} text${
          isHome ? 'Left' : 'Right'
        }`}
      >
        <span className={`${scriptShowText}`}>Show</span>
        <span className={`${scriptHideText}`}>Hide</span> Transcript
      </summary>
      {parse(transcript)}
    </details>
  );
};

export default TranscriptButton;
