import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

function Seo({ description, lang = 'en', meta = [], image, title }) {
  const { site, strapiHeaderFooter, allFile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteURL
          }
        }
        strapiHeaderFooter {
          aavm_logo {
            localFile {
              publicURL
            }
          }
        }
        allFile(filter: { name: { eq: "aavm-icon" } }) {
          nodes {
            publicURL
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;

  const metaDescription = description || site.siteMetadata.description;

  let metaImage = {};
  if (image) {
    //TODO: set this up to work with dynamic images properly!
    metaImage.url = `${site.siteMetadata.siteURL}${image.localFile.publicURL}`;
  } else {
    metaImage.url = `${strapiHeaderFooter.aavm_logo.localFile.publicURL}`;
    metaImage.height = 640;
    metaImage.width = 640;
  }

  const favicon = allFile.nodes[0].publicURL;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: 'og:image',
                  content: metaImage.url,
                },
                {
                  property: 'og:image:width',
                  content: metaImage.width,
                },
                {
                  property: 'og:image:height',
                  content: metaImage.height,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ]
        )
        .concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
}

export default Seo;
