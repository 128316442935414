import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import { hamburger, light } from './Hamburger.module.scss';

const Hamburger = ({ lightTheme, navDrawerOpen, toggleNavDrawer }) => {
  const buttonTlRef = useRef(null);
  const hamburgerRef = useRef(null);

  // Setup the open/close animation
  useEffect(() => {
    const ref = gsap.utils.selector(hamburgerRef.current);
    gsap.set([ref('#hb-top'), ref('#hb-bottom')], { opacity: 1 });
    gsap.set([ref('#hb-mid1'), ref('#hb-mid2')], {
      transformOrigin: 'center center',
    });
    buttonTlRef.current = gsap
      .timeline({ paused: true })
      .to([ref('#hb-top'), ref('#hb-bottom')], { opacity: 0, duration: 0.3 })
      .to(ref('#hb-mid1'), { rotation: '45_cw', duration: 0.3 }, 0)
      .to(ref('#hb-mid2'), { rotation: '315_ccw', duration: 0.3 }, 0);
  }, []);

  // Play the open/close animation
  useEffect(() => {
    if (navDrawerOpen) {
      buttonTlRef.current.play();
    } else {
      buttonTlRef.current.reverse();
    }
  }, [navDrawerOpen]);

  return (
    <button
      type="button"
      className={`${hamburger} ${lightTheme || navDrawerOpen ? light : ''}`}
      aria-label={navDrawerOpen ? 'Close Menu' : 'Open Menu'}
      aria-haspopup="true"
      onClick={toggleNavDrawer}
      ref={hamburgerRef}
    >
      <svg viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line
          id="hb-top"
          x1="0"
          y1="2"
          x2="32"
          y2="2"
          stroke="black"
          strokeWidth="4"
        />
        <line
          id="hb-mid1"
          x1="0"
          y1="13"
          x2="32"
          y2="13"
          stroke="black"
          strokeWidth="4"
        />
        <line
          id="hb-mid2"
          x1="0"
          y1="13"
          x2="32"
          y2="13"
          stroke="black"
          strokeWidth="4"
        />
        <line
          id="hb-bottom"
          x1="0"
          y1="24"
          x2="32"
          y2="24"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
    </button>
  );
};

export default Hamburger;
