import React from 'react';
import Play from '../icons/Play';
import { brush, home, playBtn, playSymbol } from './PlayButton.module.scss';

const PlayButton = ({ color, onClick, isHome = false }) => {
  return (
    <button
      className={`${playBtn} varColor${color} ${isHome ? home : ''}`}
      onClick={onClick}
    >
      <div className={`${brush} `}></div>
      <div className={`${playSymbol}`}>
        <Play />
      </div>
      <p className={`buttonText`}>Play Video</p>
    </button>
  );
};

export default PlayButton;
