import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import getAccentColor from '../utils/getAccentColor';
import {
  menuBg,
  menuContents,
  linkContainer,
  linkContent,
  show,
} from './NavDrawer.module.scss';

const NavDrawer = ({ navDrawerOpen, toggleNavDrawer, links }) => {
  const navRef = useRef(null); // A wrapper so we can use querySelectorAll

  useEffect(() => {
    if (navDrawerOpen) {
      disablePageScroll(navRef.current); // continue to allow scrolling on navRef.current
    } else {
      enablePageScroll(navRef.current);
    }
  }, [navDrawerOpen]);

  return (
    <div className={`${menuBg} ${navDrawerOpen ? show : ''}`} ref={navRef}>
      <ul
        data-scroll-lock-scrollable
        className={`${menuContents} pagePadding textCenter`}
      >
        <li className={`h3`}>
          <Link
            className={`varColorGreen`}
            to={`/`}
            onClick={toggleNavDrawer}
            tabIndex={navDrawerOpen ? '0' : '-1'}
          >
            Home
          </Link>
        </li>

        {links.map(({ slug, war, title }, index) => {
          const color = getAccentColor(index);
          return (
            <li key={slug} className={`mt40`}>
              <Link
                to={`/${slug}`}
                onClick={toggleNavDrawer}
                className={`varColor${color} ${linkContainer}`}
                tabIndex={navDrawerOpen ? '0' : '-1'}
              >
                <span className={`h4 ${linkContent}`}>{war}</span>
                <span className={`h3 mt6 ${linkContent}`}>{title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

NavDrawer.propTypes = {
  navDrawerOpen: PropTypes.bool,
  toggleNavDrawer: PropTypes.func,
  links: PropTypes.array,
};

export default NavDrawer;
