import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './Layout.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Seo from './components/global/Seo';

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <Seo />
      <main id="main" tabIndex="-1">
        {children}
      </main>
      <Footer />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
