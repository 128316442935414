import React from 'react';

const Play = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103 103">
      <path
        d="M51.5,103A51.5,51.5,0,1,0,0,51.5,51.5,51.5,0,0,0,51.5,103Zm-11-33.92L71.89,51,40.5,32.84Z"
        fill="#000000"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Play;
