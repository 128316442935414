const getAccentColor = (index) => {
  const modulo = index % 4;
  switch (modulo) {
    case 0:
      return 'Green';
    case 1:
      return 'Blue';
    case 2:
      return 'Beige';
    case 3:
      return 'Cream';
  }
};

export default getAccentColor;
