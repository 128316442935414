import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import parse from 'html-react-parser';

import {
  footer,
  footerTexture,
  logo,
  footerTexts,
  message,
} from './Footer.module.scss';

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      strapiHeaderFooter {
        aavm_logo {
          alternativeText
          localFile {
            publicURL
          }
        }
        footer_message
      }
    }
  `);
  const { aavm_logo, footer_message } = data.strapiHeaderFooter;

  return (
    <footer className={`pagePadding posRel pb32 ${footer} ${footerTexture}`}>
      <div className={`${logo}`}>
        <SVG
          src={aavm_logo.localFile.publicURL}
          title={aavm_logo.alternativeText}
        />
      </div>
      <div className={`mt20 textColorWhite ${footerTexts}`}>
        <div className={`${message} varColorGreen`}>
          {parse(footer_message)}
        </div>
        <p className={`pt8`}>
          © {new Date().getFullYear()} | All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
