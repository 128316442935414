import { graphql, Link, useStaticQuery } from 'gatsby';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import Hamburger from './Hamburger';
import {
  fixedContent,
  header,
  logoHome,
  skipLink,
  svgLight,
} from './Header.module.scss';
import NavDrawer from './NavDrawer';

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      strapiHeaderFooter {
        aavm_logo {
          alternativeText
          localFile {
            publicURL
          }
        }
      }
      allStrapiStories(sort: { fields: [order], order: ASC }) {
        nodes {
          slug
          war
          title
        }
      }
      site {
        pathPrefix
      }
    }
  `);

  const { aavm_logo } = data.strapiHeaderFooter;
  const { pathPrefix } = data.site;
  const navDrawerRef = useRef(null);
  const headerRef = useRef(null);
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const [lightTheme, setLightTheme] = useState(false);

  // Save the lightTheme state in a ref so that we can get it current value inside toggleLightTheme
  const lightThemeRef = useRef(lightTheme);
  lightThemeRef.current = lightTheme; // updates every render

  // Set initial color
  useEffect(() => {
    if (
      (pathPrefix && window.location.pathname === pathPrefix + '/') ||
      window.location.pathname === '/'
    ) {
      setLightTheme(true);
    }
  }, []);

  // Change color after scroll past hero
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `hamburgerColor`,
        trigger: headerRef.current,
        start: () => `top+=${window.innerHeight} top+=60px`,
        end: () => `top+=${window.innerHeight} top+=60px`,
        //markers: true,
        toggleActions: 'play none none reverse',
        onEnter: () => setLightTheme(!lightThemeRef.current),
        onEnterBack: () => setLightTheme(!lightThemeRef.current),
      },
    });

    return () => {
      // Fix umounted component error
      if (ScrollTrigger.getById('hamburgerColor'))
        ScrollTrigger.getById('hamburgerColor').kill();
      tl.kill();
    };
  }, []);

  const toggleNavDrawer = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };

  useEffect(() => {
    if (navDrawerOpen) {
      // Update the aria-expanded attribute
      navDrawerRef.current.setAttribute('aria-expanded', true);
      document.body.style.overflowY = 'hidden';
    } else if (!navDrawerOpen) {
      // Update the aria-expanded attribute
      navDrawerRef.current.setAttribute('aria-expanded', false);
      document.body.style.overflowY = 'unset';
    }
  }, [navDrawerRef, navDrawerOpen]);

  const focusMainContent = () => {
    document.querySelector('#main').focus();
  };

  return (
    <header className={`${header}`} ref={headerRef}>
      <Link
        to="#main"
        className={`srOnly textColorWhite ${skipLink}`}
        onClick={focusMainContent}
      >
        Skip to Main Content
      </Link>
      <nav>
        <div className={`pagePadding`}>
          <Link
            className={`button ${logoHome} ${
              lightTheme || navDrawerOpen ? svgLight : ''
            }`}
            to={`/`}
          >
            <SVG
              src={aavm_logo.localFile.publicURL}
              title={aavm_logo.alternativeText}
            />
          </Link>
        </div>

        <div className={fixedContent} data-scroll-lock-fill-gap>
          <Hamburger
            lightTheme={lightTheme}
            navDrawerOpen={navDrawerOpen}
            toggleNavDrawer={toggleNavDrawer}
          />

          <div
            id="navDrawer"
            ref={navDrawerRef}
            aria-label="All Pages"
            aria-expanded="false"
          >
            <NavDrawer
              links={data.allStrapiStories.nodes}
              navDrawerOpen={navDrawerOpen}
              toggleNavDrawer={toggleNavDrawer}
            />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
